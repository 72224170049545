import { useQuery } from '@apollo/client';
import React from 'react';

import { CommentUnreadCountDocument } from 'frontend/api/generated';
import { AnnotationDots } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { getCommentPath } from 'frontend/features/Comments/utils/helpers';
import { toggleComments } from 'frontend/state/dux/comments';
import { useAppDispatch } from 'frontend/state/hooks';

import styles from '../../Navbar.scss';

const CommentsButton = () => {
  const dispatch = useAppDispatch();

  const pathname = window.location.pathname;

  const { level, levelId, category } = getCommentPath(pathname) || {};

  const { data } = useQuery(CommentUnreadCountDocument, {
    variables: {
      level: level as string,
      levelId: levelId as string,
      category: category as string,
      path: pathname,
      filterType: 'ALL',
      filterFlag: 'NONE',
    },
    pollInterval: 20000,
  });

  return (
    <div className={styles.navbarButtonWrapper}>
      {(data?.unreadCommentsCount?.count || 0) > 0 && <span className={styles.badge} />}
      <button
        color="kindlyAdmin"
        type="button"
        className={styles.navbarBtn}
        aria-label="Open Comments"
        onClick={() => {
          dispatch(toggleComments());
        }}
      >
        <Icon component={AnnotationDots} title="Comments" />
      </button>
    </div>
  );
};

export default CommentsButton;
