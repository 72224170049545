import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { EngagementDashboardTokenDocument } from 'frontend/api/generated';
import { Brain, Filter, PieChart, Server, Settings, Users } from 'frontend/assets/icons';
import { LUZMO_DASHBOARDS } from 'frontend/features/Analytics/components/EmbeddedAnalytics/constants';
import useBetaSettings from 'frontend/features/Organization/views/Settings/components/BetaSettings/useBetaSettings';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import Menu from './Menu';

export default function OrganizationMenu() {
  const { organizationId } = useParams();
  const { organizationPermissions } = useMyPermissions({ organizationId });
  const isOrgManager = organizationPermissions.includes('manage_organization');
  // const { organizationInboxEnabled } = useOrganizationInboxEnabled();
  // const { bots } = useOrganizationBotsWithPermission();
  const { initialValues } = useBetaSettings();
  const { data: token } = useQuery(EngagementDashboardTokenDocument, {
    variables: { organizationId: organizationId as string },
    skip: !organizationId,
  });

  const showInbox = false; // organizationInboxEnabled && bots?.length > 0;

  const menuItems = [
    { name: 'Workspaces', slug: 'workspaces', icon: Server },
    {
      name: 'Analytics',
      slug: 'analytics',
      path: '/analytics/*',
      icon: PieChart,
      submenu: [
        {
          name: 'Reports',
          subNavigation: true,
          slug: 'reports',
        },
      ],
    },
    { name: 'Inbox', slug: 'inbox', icon: Filter, hide: !showInbox },
    { name: 'Skills', slug: 'skills', icon: Brain },
    { name: 'Members', slug: 'members', icon: Users },
    {
      name: 'Billing',
      slug: LUZMO_DASHBOARDS.ESTIMATED_COSTS.route,
      icon: Filter,
      hide: !initialValues.enableOrganizationBilling || !isOrgManager,
    },
    { name: 'Settings', slug: 'settings', icon: Settings, hide: !isOrgManager },
  ];

  if (Object.keys(token?.engagementDashboardToken || {}).length) {
    menuItems[1]?.submenu?.push({
      name: 'Conversions',
      subNavigation: true,
      slug: 'conversions',
    });
  }

  return <Menu menuItems={menuItems} />;
}
